import type Sentry from "@sentry/browser";

let SentryInstance: typeof Sentry | undefined;

export default () => {
  // Skip error reporting on legacy browsers
  // (which don’t support Object.fromEntries).
  if (!("fromEntries" in Object)) {
    return;
  }

  const onFirstError = async (event: ErrorEvent) => {
    window.removeEventListener("error", onFirstError);
    reportError(event.error);
  };

  window.addEventListener("error", onFirstError);
};


const initSentry = async () => {
  SentryInstance = await import("@sentry/browser");

  SentryInstance.init({
    dsn: "https://df1672b1dfa9eae1074d010d2f3298a8@o4508256406208512.ingest.de.sentry.io/4508357923700816",
    autoSessionTracking: false,
  });
};

const reportError = async (error: unknown) => {
  console.error(error);
  if (!SentryInstance) await initSentry();
  SentryInstance?.captureException(error);
};
